@use '../../styles/utils' as *

.root
  //position: relative

.chartBar
  width: 100%
  position: relative
  height: 48px !important

.editRoot
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0

.editOverlay
  position: absolute
  z-index: 1
  background: rgba(0,0,0,0.7)
  width: 100%
  height: 100%
  top: 0
  left: 0
  
.edit
  position: absolute
  z-index: 2
  display: flex
  gap: 10px

.editButton
  background: white
  border: 1px solid black
  border-radius: 4px
  padding: 5px 10px