.modal_footer
  display: flex
  font-size: 14px
  justify-content: flex-end
  padding: 24px
  gap: 8px
  .modal_apply
    padding: 9px 24px 10px 24px
    font-weight: 500
    line-height: 21px
    letter-spacing: 0.1px
    border: 1px solid #7A7768
    border-radius: 12px
  .modal_apply:hover
    background-color: #f1edf1
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)

