.baseButton {
    &[data-variant='primary'] {
        background-color: #FFE600;
        border: 1px solid #959181;
        color: white;
        border-radius: 12px;
    }

    &[data-variant='primary']:hover {
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    }

    &[data-variant='on-secondary'] {
        background-color: #ffff;
        border: 1px solid #7A7768;
        color: white;
        border-radius: 12px;
    }

    &[data-variant='on-secondary']:hover {
        background-color: #6750A414;
    }

    &[data-variant='error'] {
        background-color: #BA1A1A;
        border: 1px solid #959181;
        color: white;
        border-radius: 12px;
    }
}