.input-text-field {
  height: 3rem;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: rgb(var(--color-outline));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: rgb(var(--color-on-surface-variant));
  outline: none;
}

.input-text-field::placeholder {
  color: #999;
}

.input-text-field:focus {
  outline: none;
  border-width: 2px;
  border-color: rgb(var(--color-on-surface));
}
.input-text-field:hover {
  outline: none;
  border-width: 1px;
  border-color: rgb(var(--color-on-surface));
}
