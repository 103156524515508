@use '../../styles/utils' as *
$cBorder: $gray400

.selectContainer
  display: flex
  min-width: 160px
  height: 48px
  justify-content: space-between
  position: relative
  cursor: pointer

.select
  line-height: 28px
  border: 1px solid #959181
  border-radius: 4px
  font-size: 16px
  width: 100%
  padding: 10px 16px
  cursor: pointer

  &::placeholder
    font-size: 14px

.arrow
  width: 48px
  display: flex
  position: absolute
  height: 48px
  right: 0


.arrowIcon
  margin: auto

.option
  height: 240px
  padding: 100px
