$pureBlack: #000
$pureWhite: #FFF
$blue: #1DA1F2
$orange: #fffbf8

$pureBlackAlpha90: rgba($pureBlack, .9)
$pureBlackAlpha80: rgba($pureBlack, .8)
$pureBlackAlpha60: rgba($pureBlack, .6)
$pureBlackAlpha40: rgba($pureBlack, .4)
$pureBlackAlpha20: rgba($pureBlack, .2)

$pureWhiteAlpha80: rgba($pureWhite, .8)
$pureWhiteAlpha60: rgba($pureWhite, .6)
$pureWhiteAlpha40: rgba($pureWhite, .4)
$pureWhiteAlpha20: rgba($pureWhite, .2)
$pureWhiteAlpha10: rgba($pureWhite, .1)

$gray600: #333333
$gray500: #3d3d3d
$gray400: #959181
$gray300: #b9b9b9
$gray100: #f5f5f5

$transparent: transparent
