@tailwind base;

@layer base {
  :root {
    /* Main color */
    --color-background: 255 255 255;
    --color-error: 186 26 26;
    --color-error-container: 255 218 214;
    --color-on-error: 255 255 255;
    --color-on-error-container: 65 0 2;
    --color-on-primary: 31 28 0;
    --color-on-secondary: 255 255 255;
    --color-on-secondary-container: 31 28 5;
    --color-on-tertiary: 255 255 255;
    --color-on-tertiary-container: 0 32 32;
    --color-on-surface: 29 28 22;
    --color-on-surface-variant: 97 95 80;
    --color-outline: 149 145 129;
    --color-outline-variant: 232 226 208;
    --color-primary: 255 230 0;
    --color-secondary: 99 95 65;
    --color-secondary-container: 234 227 189;
    --color-surface-variant: 232 226 208;
    --color-tertiary: 0 106 106;
    --color-tertiary-container: 173 255 255;
    --color-surface: 253 251 247;
    --color-gray-background: 233 231 222;
    --color-gray-background-1: 247 245 240;

    /* M3 color */

    --color-M3-surface: 254 251 255;
    --color-M3-surface-variant: 73 69 79;
    --color-M3-outline: 122 119 104;
    --color-M3-light-state-layer-primary: 103 80 164;
    --color-M3-outline-variant: 203 199 181;

    /* Color Primary */

    --color-primary-0: 0 0 0;
    --color-primary-10: 31 28 0;
    --color-primary-20: 54 49 0;
    --color-primary-30: 78 72 0;
    --color-primary-40: 104 96 0;
    --color-primary-50: 131 121 0;
    --color-primary-60: 159 147 0;
    --color-primary-70: 188 174 0;
    --color-primary-80: 217 201 0;
    --color-primary-90: 248 230 0;
    --color-primary-95: 255 243 147;
    --color-primary-99: 255 254 245;
    --color-primary-100: 255 255 255;

    /* Color Secondary */

    --color-secondary-0: 0 0 0;
    --color-secondary-10: 31 28 5;
    --color-secondary-20: 52 49 23;
    --color-secondary-30: 75 71 44;
    --color-secondary-40: 99 95 65;
    --color-secondary-50: 124 120 88;
    --color-secondary-60: 151 146 112;
    --color-secondary-70: 178 172 137;
    --color-secondary-80: 205 199 163;
    --color-secondary-90: 234 227 189;
    --color-secondary-95: 249 242 203;
    --color-secondary-99: 255 251 255;
    --color-secondary-100: 255 255 255;

    /* Color Tertiary */
    --color-tertiary-0: 0 0 0;
    --color-tertiary-10: 0 32 32;
    --color-tertiary-20: 0 55 55;
    --color-tertiary-30: 0 79 80;
    --color-tertiary-40: 0 106 106;
    --color-tertiary-50: 0 133 133;
    --color-tertiary-60: 0 161 162;
    --color-tertiary-70: 30 190 190;
    --color-tertiary-80: 76 218 218;
    --color-tertiary-90: 111 247 247;
    --color-tertiary-95: 173 255 255;
    --color-tertiary-99: 241 255 254;
    --color-tertiary-100: 255 255 255;

    /* Color Error */
    --color-error-variant-0: 0 0 0;
    --color-error-variant-10: 65 0 2;
    --color-error-variant-20: 105 0 5;
    --color-error-variant-30: 147 0 10;
    --color-error-variant-40: 186 26 26;
    --color-error-variant-50: 222 55 48;
    --color-error-variant-60: 255 84 73;
    --color-error-variant-70: 255 137 125;
    --color-error-variant-80: 255 180 171;
    --color-error-variant-90: 255 218 214;
    --color-error-variant-95: 255 237 234;
    --color-error-variant-99: 255 251 255;
    --color-error-variant-100: 255 255 255;

    /* Color Neutral  */

    --color-neutral-0: 0 0 0;
    --color-neutral-10: 29 28 22;
    --color-neutral-20: 50 48 42;
    --color-neutral-30: 73 71 64;
    --color-neutral-40: 96 94 87;
    --color-neutral-50: 122 119 112;
    --color-neutral-60: 148 144 137;
    --color-neutral-70: 174 171 163;
    --color-neutral-80: 202 198 190;
    --color-neutral-90: 230 226 217;
    --color-neutral-95: 245 240 231;
    --color-neutral-99: 253 251 247;
    --color-neutral-100: 255 255 255;

    /* Color Neutral  Variant */

    --color-neutral-variant-0: 0 0 0;
    --color-neutral-variant-10: 29 28 17;
    --color-neutral-variant-20: 51 49 36;
    --color-neutral-variant-30: 73 71 58;
    --color-neutral-variant-40: 97 95 80;
    --color-neutral-variant-50: 122 119 104;
    --color-neutral-variant-60: 149 145 129;
    --color-neutral-variant-70: 175 171 154;
    --color-neutral-variant-80: 203 199 181;
    --color-neutral-variant-90: 232 226 208;
    --color-neutral-variant-95: 246 241 222;
    --color-neutral-variant-99: 255 251 255;
    --color-neutral-variant-100: 255 255 255;

    /* Color Surfaces */

    --color-surface: var(--color-neutral-99);
    --color-surface-1: var(--color-primary-40);

    /* Color state */
    --color-disabled: 225 223 221;
    --color-on-disabled: 151 149 146;

    /* Break Point */
    --breakpoint-xs: 375;
    --breakpoint-sm: 600;
    --breakpoint-md: 904;
    --breakpoint-2md: 1239;
    --breakpoint-lg: 1439;
    --breakpoint-xl: 1648;

    /* Display */
    --display-ruby: ruby;
  }

  /* Dark mode */
  :root[data-theme='dark'] {
    --color-primary: 255 115 179;
    --color-secondary: 111 114 185;
  }
}
