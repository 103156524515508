.box_select
  height: 48px
  display: flex
  position: relative
  select
    padding: 0 16px
    border: 1px solid #959181
    border-radius: 4px
  .icon_select
    width: 45px
    justify-content: center
    align-items: center
    display: flex
    position: absolute
    height: 45px
    top: 1px
    right: 2px
