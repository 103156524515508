@use '../../styles/utils' as *
$cBorder: $gray300

.root
  line-height: 0

.mark
  border-radius: 2px
  transform-origin: 50% 50%
  transform: rotate(-45deg)
  
  &.sowing
    background: #9F9300
  &.planting
    background: #00A1A2