@import '@silevis/reactgrid/styles.css';

.business-table {
  width: 100%;
  margin: auto;
  overflow-x: auto;
  height: 100%;
}

.reactgrid {
  width: 0 !important;
}

.reactgrid-content {
  width: fit-content !important;
}

.rg-pane rg-pane-center-middle {
  width: auto !important;
}

.business-table .reactgrid-content .rg-pane .rg-cell {
  padding: 0;
  font-size: 12px;
  color: rgb(var(--color-on-surface));
}

.business-table .padding-left-6 {
  padding-left: 24px !important;
}

.business-table .padding-left-4_5 {
  padding-left: 18px !important;
}

.business-table .padding-right-3 {
  padding-right: 12px !important;
}

.business-table .padding-y-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.business-table .padding-x-1_5 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.business-table .justify-content-center {
  justify-content: center !important;
}

.business-table .justify-content-end {
  justify-content: flex-end;
}
.business-table .align-items-end {
  align-items: flex-end !important;
}

.business-table .font-bold {
  font-weight: 600 !important;
}

.business-table .text-on-surface-variant {
  color: rgb(var(--color-on-surface-variant)) !important;
}

.business-table .text-error {
  color: rgb(var(--color-error)) !important;
}

.business-table .text-on-surface {
  color: rgb(var(--color-on-surface));
}

.business-table .text-tertiary {
  color: rgb(var(--color-tertiary)) !important;
}

.business-table .text-3_5 {
  font-size: 14px !important;
}

.business-table .text-4 {
  font-size: 16px !important;
}

.business-table .rg-celleditor input {
  font-family: 'DM Sans', sans-serif;
}

.business-table .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: rgb(var(--color-tertiary)) !important;
  border-width: 2px;
}

.business-table .leading-150 {
  line-height: 150% !important;
}
.business-table .leading-175 {
  line-height: 175% !important;
}
.business-table .tracking-8 {
  word-spacing: 0.8px !important;
}
.business-table .bg-surface-at-2 {
  background-color: rgb(var(--color-surface) / 8) !important;
}
.business-table .border-b-none {
  border-bottom: none !important;
}

.business-table .h-12 {
  height: 48px !important;
}

.business-table .input-text-field {
  height: 3rem;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: rgb(var(--color-outline));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: rgb(var(--color-on-surface-variant));
  outline: none;
  background-color: white;
}

.business-table .input-text-field::placeholder {
  color: #999;
}

.business-table .input-text-field:focus {
  outline: none;
  border-width: 2px;
  border-color: rgb(var(--color-on-surface));
}
.business-table .input-text-field:hover {
  outline: none;
  border-width: 1px;
  border-color: rgb(var(--color-on-surface));
}

.border-M3-outline {
  border-color: rgb(var(--color-outline));
}

.business-table .rg-celleditor {
  box-sizing: border-box;
  z-index: 20 !important;
  background-color: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  border-style: solid;
  padding: 0 4px;
  color: rgb(var(--color-on-surface));
  font-size: 12px;
  border-width: 1px;
}


.reactgrid-content .rg-pane.rg-pane-left {
  z-index: 9 !important;
}

.reactgrid-content .rg-pane.rg-pane-top {
  z-index: 10;
}

.reactgrid-content .rg-pane-shadow.shadow-top-left-corner {
  z-index: 20 !important;
}

.left-500 {
  left: 500px !important;
}

.left-1000 {
  left: 1000px !important;
}

.left-1500 {
  left: 1500px !important;
}

.left-2000 {
  left: 2000px !important;
}

.left-2500 {
  left: 2500px !important;
}

.left-3000 {
  left: 3000px !important;
}