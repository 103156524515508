@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind components;

@layer components {
  /* Headline */
  .headline {
    @apply break-words font-noto font-normal leading-[1.5em];
  }
  .headline.lg {
    @apply text-2xl;
  }
  .headline.md {
    @apply text-xl;
  }
  .headline.sm {
    @apply text-lg;
  }

  /* Label */
  .label {
    @apply break-words font-noto font-medium leading-[1.5em];
  }
  .label.lg {
    @apply text-sm tracking-1;
  }
  .label.md {
    @apply text-xs tracking-5;
  }
  .label.sm {
    @apply text-2xs tracking-5;
  }

  /* Label */
  .regular-text {
    @apply break-words font-noto font-normal leading-[1.75em];
  }
  .regular-text.lg {
    @apply text-base tracking-5;
  }
  .regular-text.md {
    @apply text-sm tracking-2;
  }
  .regular-text.sm {
    @apply text-xs tracking-4;
  }
}
