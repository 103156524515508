@use '../../styles/utils' as *
$cBg: $pureWhite
$cText: $gray500
$cBorder: $gray300
$cDisclaimer: $gray600

.root
  display: flex
  position: relative
  overflow-y: auto
  flex-grow: 1
  min-height: 300px
  height: 100%

.chartNameArea
  margin-top: 78px
  width: 240px
  padding-bottom: 30px
  overflow-x: hidden

@media screen and (max-width: 768px)
  .chartNameArea
    width: 180px


.chartNameArea::-webkit-scrollbar
  display: none

.term
  margin-left: 270px
  display: flex
  align-items: center
  gap: 16px
