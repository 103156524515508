.modal_header
  .box_header
    background: white
    display: flex
    justify-content: space-between
    .box_left
      display: flex
      align-items: center
      .iconClose
        display: flex
        width: 48px
        height: 48px
        justify-content: center
        align-items: center
      .title_page
        padding: 0 16px
    .btn_event_cancel
      margin: auto 10px
      cursor: pointer
      transition: all .2s ease-in

      &:active
        transition: all .2s ease-in
        transform: scale(0.98)
