.header_box
  height: 64px
  background-color: #ffffff
  padding: 8px 32px
  display: flex
  justify-content: space-between
  .titlePage
    display: flex
    align-items: center
    gap: 4px
    .menu_sp
      display: none
  .setting_icon
    position: relative
    .icon_sp
      display: none
    svg
      cursor: pointer

@media only screen and (max-width: 904px)
  .header_box
    padding: 12px 16px
    .titlePage
      .menu_sp
        display: flex
    .setting_icon
      .icon
        //display: none
      .icon_sp
       display: initial
