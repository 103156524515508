@use '../../styles/utils' as *

.root
  display: flex
  flex-direction: column
  width: 99%
  height: 100%
  min-width: 350px

.controlWrap
  display: flex
  align-items: center
  gap: 18px
  margin: 20px
  justify-content: flex-end

.areaGrouping
  display: flex
  align-items: center
  gap: 8px

.groupingCheck
  width: 14px
  height: 14px

.zoom
  position: fixed
  top: 0
  left: 0
  z-index: 999
  background: white
  height: 100vh
  width: 100vw
