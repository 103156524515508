$yellow_primary: #F8E600;

input[type="checkbox"] {
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid #959181;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  &::before {
    position: absolute;
    content: '';
    display: block;
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    border-style: solid;
    border-color: #1F1C00;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  &:checked {
    border-color: $yellow_primary;
    background: $yellow_primary;

    &::before {
      opacity: 1;
    }
  }
}