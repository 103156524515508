@use '../../styles/utils' as *
$cBorder: $gray300

.root
  position: absolute

.edit
  position: absolute
  left: 0
  top: -50px
  display: none

.show
  display: flex