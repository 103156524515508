.displayImg
  position: relative
  width: 280px

  &:hover
    img
      cursor: pointer
      opacity: 0.7
      transition: all .5s ease-in

    .close
      display: block

  img
    width: 280px
    height: 160px
    object-fit: cover
    transition: all .5s ease-in

  .close
    display: none
    position: absolute
    top: 8px
    right: 8px
    padding: 1px
    border: 1px solid #EAE3BD
    border-radius: 100%
    background: #EAE3BD
    color: #1F1C05

@media only screen and (max-width: 768px)
  .displayImg
    width: 100%

    img
      width: 100%
