@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './components/styles/common/index.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
body{
  font-family: 'Noto Sans JP', sans-serif;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar{
  display: none
}

#list-circle::-webkit-scrollbar{
  display: none
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* scroll-y-mobile */
.overflow-scroll-mb::-webkit-scrollbar {
  width: 8px;
}

.overflow-scroll-mb {
  height: calc(100vh - 112px);
  overflow-y: auto;
}

/* scroll-y */
.overflow-scroll-y::-webkit-scrollbar {
  width: 8px;
}

.overflow-scroll-y {
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.overflow-scroll-xy {
  overflow-y: auto;
  overflow-x: auto;
}

/* scroll-y */
.overflow-scroll-y-mb::-webkit-scrollbar {
  width: 8px;
}

.overflow-scroll-y-mb {
  height: calc(100vh - 64px);
  overflow-y: auto;
}

/* scroll-modal-mb */
.overflow-scroll-modal-mb::-webkit-scrollbar {
  width: 8px;
}

.overflow-scroll-modal-mb {
  overflow-y: auto;
}

@media screen and (max-width: 904px) {
  .overflow-scroll-y-mb {
    overflow-y: hidden;
  }
}

/* scroll-xy modal mb */
.overflow-scroll-modal::-webkit-scrollbar {
  width: 8px;
}

.overflow-scroll-modal {
  height: calc(100vh - 215px);
  overflow-y: auto;
}

/* scroll menu sidebar */
.overflow-scroll-menu::-webkit-scrollbar {
  width: 4px;
}

.overflow-scroll-menu {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
  overflow-x: hidden;
}

/* houseComponentListColumn */
.houseComponentListColumn {
  display: flex;
}

.houseComponentListColumn>*:nth-child(1) {
  width: calc((320 + 80) / 1104 * 100%);
}

.houseComponentListColumn>*:nth-child(2) {
  width: calc(144 / 1104 * 100%);
}

.houseComponentListColumn>*:nth-child(3) {
  width: calc(144 / 1104 * 100%);
}

.houseComponentListColumn>*:nth-child(4) {
  width: calc(144 / 1104 * 100%);
}

.houseComponentListColumn>*:nth-child(5) {
  width: calc(144 / 1104 * 100%);
}

.houseComponentListColumn>*:nth-child(6) {
  width: calc(128 / 1104 * 100%);
}

/* QuestionComponentListColumn */
.questionComponentListColumn {
  display: flex;
}

.questionComponentListColumn>*:nth-child(1) {
  width: calc((460) / 1104 * 100%);
}

.questionComponentListColumn>*:nth-child(2) {
  width: calc(146 / 1104 * 100%);
}

.questionComponentListColumn>*:nth-child(3) {
  width: calc(166 / 1104 * 100%);
}

.questionComponentListColumn>*:nth-child(4) {
  width: calc(166 / 1104 * 100%);
}

.questionComponentListColumn>*:nth-child(5) {
  width: calc(166 / 1104 * 100%);
}

/* GuideComponenttListColumn */
.guideComponentListColumn {
  display: flex;
}

.guideComponentListColumn>*:nth-child(1) {
  width: calc((616 + 16) / 1104 * 100%);
}

.guideComponentListColumn>*:nth-child(2) {
  width: calc(138 / 1104 * 100%);
}

.guideComponentListColumn>*:nth-child(3) {
  width: calc(138 / 1104 * 100%);
}

.guideComponentListColumn>*:nth-child(4) {
  width: calc(138 / 1104 * 100%);
}

.guideComponentListColumn>*:nth-child(5) {
  width: calc(58 / 1104 * 100%);
}

svg {
  cursor: pointer;
}

.before-rotate-0 {
  transform: rotate(180deg);
  transition: width 1s;
}

.rotate-180 {
  transform: rotate(0deg);
  transition: width 1s;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overlay-dark::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--color-M3-on-secondary-container));
  opacity: 0.12;
}

.overlay-dark {
  position: relative;
  overflow: hidden;
}

.overlay-on-hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: rgb(var(--color-M3-on-secondary-container));
  opacity: 0;
}

.overlay-on-hover:hover::before {
  opacity: 0.08;
}

.overlay-on-hover:focus::before {
  opacity: 0.12;
}

.overlay-on-hover {
  position: relative;
  overflow: hidden;
}

textarea:focus {
  outline: none !important;
  border-color: #719ece;
}

.dropdown-container {
  border-color: #959181 !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown-container .dropdown-content {
  bottom: 100% !important;
}

.truncate-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
}

.dropdown-heading {
  cursor: pointer !important;
}

.dropdown-content .item-renderer {
  align-items: center !important;
  gap: 3px;
}

.dropdown-content .item-renderer input[type="checkbox"] {
  min-width: 18px;
}

select {
  appearance: none;
  -webkit-appearance: none;
  font-family: 'Noto Sans JP', sans-serif;
}

select::-ms-expand {
  display: none;
}

.react-responsive-modal-modal .container {
   max-width: none;
}

.react-datepicker-wrapper {
  width: 100%;
}
