.setting_box
  position: absolute
  background-color: #efede6
  width: 200px
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2)
  padding: 8px 0
  top: 36px
  z-index: 1
  .setting_list_item
    .setting_item, .delete_item
      height: 40px
      padding: 0 16px 0 16px
      font-weight: 400
      font-size: 16px
      line-height: 28px
      letter-spacing: 0.5px
      display: flex
      align-items: center
      color: #1D1C16
      cursor: pointer
      transition: all .2s ease-in

      svg
        width: 24px
        height: 24px
        margin-right: 20px

      &:hover
        background: rgba(0, 0, 0, 0.08)
        transition: all .2s ease-in
    .delete_item
      color: #BA1A1A

@media only screen and (max-width: 768px)
  .setting_box
    width: 181px
    min-width: auto
