.filter_row
  border-radius: 8px
  border: 1px solid #E8E2D0
  padding: 12px
  display: flex
  gap: 8px
  margin-bottom: 1px
  .label_row
    font-size: 16px
    letter-spacing: -1px
    line-height: 28px
    display: flex
    align-items: center

.modal_body_sp
  max-height: calc(100vh - 120px)
  height: 100%
  overflow-y: auto
  background: white
  padding: 16px 24px
  .filter_row_sp
    justify-content: space-between
    display: flex
    border-radius: 8px
    border: 1px solid #E8E2D0
    padding: 8px
    flex-wrap: wrap
    margin-bottom: 8px
