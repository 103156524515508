@use '../../styles/utils' as *
$cBorder: $gray400

.root
  font-size: 16px
  padding: 10px
  border: 1px solid $cBorder
  border-radius: 4px

  &::placeholder
    font-size: 14px

  &:focus
    outline: none
