@import '../vendor/index.css';
@import '../utils/index.css';

.ruby {
  display: ruby;
}

.ruby-block {
  display: ruby-block;
}
