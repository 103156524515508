/* Icon abtraction */
.icon-button i:first-child::before {
  content: '';
  mask-repeat: no-repeat;
  width: inherit;
  height: inherit;
}

.icon-button i:first-child {
  display: flex;
}

.icon-input i:first-child::before {
  content: '';
  mask-repeat: no-repeat;
  width: inherit;
  height: inherit;
}

.icon-input i:first-child {
  display: flex;
}

.icon-24px {
  content: '';
  mask-repeat: no-repeat;
  width: inherit;
  height: inherit;
  background-color: inherit;
  display: block;
}

/* Icon add */

.icon-button-add i:first-child::before {
  mask-image: url('~/public/asset/image/icons/add.svg');
}

.icon-add {
  mask-image: url('~/public/asset/image/icons/add.svg');
}

/* Icon filter_list */

.icon-button-filter_list i:first-child::before {
  mask-image: url('~/public/asset/image/icons/filter_list.svg');
}
.icon-filter_list {
  mask-image: url('~/public/asset/image/icons/filter_list.svg');
}
/* Icon search */

.icon-button-search i:first-child::before {
  mask-image: url('~/public/asset/image/icons/search.svg');
}
.icon-search {
  mask-image: url('~/public/asset/image/icons/search.svg');
}

/* Icon close */
.icon-button-close i:first-child::before {
  mask-image: url('~/public/asset/image/icons/close.svg');
}
.icon-close {
  mask-image: url('~/public/asset/image/icons/close.svg');
}
/* Icon drop down */
.icon-button-arrow_drop_down i:first-child::before {
  mask-image: url('~/public/asset/image/icons/arrow_drop_down.svg');
}
.icon-arrow_drop_down {
  mask-image: url('~/public/asset/image/icons/arrow_drop_down.svg');
}

/* Icon navigate next */
.icon-button-navigate_next i:first-child::before {
  mask-image: url('~/public/asset/image/icons/navigate_next.svg');
}
.icon-navigate_next {
  mask-image: url('~/public/asset/image/icons/navigate_next.svg');
}

/* Icon navigate before */
.icon-button-navigate_before i:first-child::before {
  mask-image: url('~/public/asset/image/icons/navigate_before.svg');
}
.icon-navigate_before {
  mask-image: url('~/public/asset/image/icons/navigate_before.svg');
}

/* Icon indicator */
.icon-button-indicator i:first-child::before {
  mask-image: url('~/public/asset/image/icons/indicator.svg');
}
.icon-indicator {
  mask-image: url('~/public/asset/image/icons/indicator.svg');
}

/* Icon mode edit */
.icon-button-mode_edit i:first-child::before {
  mask-image: url('~/public/asset/image/icons/mode_edit.svg');
}
.icon-mode_edit {
  mask-image: url('~/public/asset/image/icons/mode_edit.svg');
}

/* Icon share */
.icon-button-share i:first-child::before {
  mask-image: url('~/public/asset/image/icons/share.svg');
}
.icon-share {
  mask-image: url('~/public/asset/image/icons/share.svg');
}

/* Icon more */
.icon-button-more > i::before {
  mask-image: url('~/public/asset/image/icons/more.svg');
}
.icon-more {
  mask-image: url('~/public/asset/image/icons/more.svg');
}

/* Icon more */
.icon-button-delete > i::before {
  mask-image: url('~/public/asset/image/icons/delete.svg');
}
.icon-delete {
  mask-image: url('~/public/asset/image/icons/delete.svg');
}
