.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden;
  height: 100%;
}

.carousel .carousel-status {
  display: none;
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height 0.15s ease-in;
  height: 100%;
}

.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%;
  height: 100%;
}