.btn_normal
  display: flex
  padding: 9px 24px 10px 24px
  justify-content: center
  align-items: center
  font-weight: 500
  line-height: 21px
  border: 1px solid #7A7768
  border-radius: 12px
  font-size: 14px
  width: 77px
  height: 40px
  letter-spacing: -1px