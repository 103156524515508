@use '../../styles/utils' as *
$cBorder: #E8E2D0
$cBg: #FDFBF7
$cColor: #615F50

.root
  overflow-y: auto
  position: relative

.rootChart
  height: fit-content
  overflow-y: hidden
  overflow-x: hidden
  font-family: 'Noto Sans JP', sans-serif

.table
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  margin: 0
  padding: 0
  color: $cColor
  font-size: 10px
  background: $cBg
  min-height: 389px

.head
  height: 24px
  text-align: center
  font-size: 11px
  box-sizing: inherit

.body
  height: 100%
  position: relative

.bodyBefore
  position: absolute
  top: 0
  right: 0
  height: 100%
  width: 1px
  background-color: #1D1C16

.charts
  height: fit-content
  position: absolute
  padding-top: 40px

.dummy
  display: block
  height: 48px

.headerChartTop
  &:before
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 1px
    background-color: #E8E2D0
    z-index: 1

.headerChart
  &:before
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 1px
    background-color: #E8E2D0
    z-index: 1
  &:after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    right: 0
    height: 1px
    background-color: #E8E2D0
    z-index: 1
