@use '../../styles/utils' as *
$cBorder: #615F50
$cText: #615F50
$cBg: #fff

.root
  position: absolute
  height: 100%
  display: flex
  align-items: center

.moveDate
  background: #1D1C16
  position: absolute
  top: -20px
  left: -36px
  border: 1px solid $cBorder
  border-radius: 4px
  min-width: 70px
  padding: 2px
  text-align: center
  height: 26px
  display: flex
  align-items: center
  justify-content: center
  color: white
  font-size: 12px
  font-weight: 500
  &:after
    content: ""
    position: absolute
    top: 100%
    left: 50%
    margin-left: -5px
    border-width: 8px
    border-style: solid
    border-color: #1D1C16 transparent transparent transparent
