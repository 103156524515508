.container_full
  display: flex
  justify-content: space-between
  height: 100%
  overflow: hidden
  .content_full
    width: calc(100% - 240px)
    font-size: 24px
    line-height: 36px
    height: 100%
    transition: width 0.5s
  .content_full_narrow
    width: calc(100% - 70px)
    transition: width 0.5s

@media only screen and (max-width: 768px)
  .container_full
    display: block
    .content_full, .content_full_narrow
      width: 100%
