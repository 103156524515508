.modal
  width: 720px
  height: 531px
  border-radius: 16px
  padding: 24px
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.30), 0 4px 8px 3px rgba(0, 0, 0, 0.15)
.modal_sp
  width: auto
  height: 100vh
  background: #e9e7de !important
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)