@use '../../styles/utils' as *
$cBorder: $gray300

.root
  position: relative
  background: #FF5449
  display: flex
  justify-content: center
  align-items: center

.term
  display: block
  font-size: 10px
  color: #959181
  width: 34px
  height: 16px
  text-align: center
  background: #fff
  border: 1px solid #959181
  border-radius: 2px
  
.tooltip
  position: absolute
  left: -10px
  top: -10px
  display: none

.display
  display: block