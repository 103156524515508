@use '../utils' as *
$cBg: $pureWhite
$cContentBg: $orange
$cBaseFont: $pureBlack
$cScrollThumb: $pureWhiteAlpha40
$cScrollTrack: $transparent
*,
*::before,
*::after
  margin: 0
  padding: 0
html,
body
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif
  line-height: 1.4
  background-color: $cBg
  position: relative
  color: $cBaseFont
  z-index: 0
  word-break: break-word
  height: 100%
html
  scrollbar-color: $cScrollThumb rgba(0,0,0,0)
  scrollbar-width: thin
.business-table
  scrollbar-color: auto
  scrollbar-width: thin
body
  overflow-y: scroll

/* WebKit */
body::-webkit-scrollbar 
  width: none
body::-webkit-scrollbar-track
  background-color: $cContentBg
body::-webkit-scrollbar-thumb 
  background-color: #888
  
/* Firefox */
body
  scrollbar-width: 8px
body::-moz-scrollbar 
  width: 10px
body::-moz-scrollbar-track 
  background-color: $cContentBg
body::-moz-scrollbar-thumb 
  background-color: #888

#root
  width: 100%
  height: 100%

  
#__next
  height: 100%
li
  list-style-type: none
input[type='text']
  word-break: keep-all
input[type="text"][disabled]
  opacity: 1
a
  color: inherit
  text-decoration: none
button
  border: none
  background: none

select
  appearance: none
  -webkit-appearance: none

table
  border-collapse: collapse