@use '../../components/styles/utils' as *
.sidebar, .sidebar_narrow, .sidebar_sp, .sidebar_sp_toggle
  width: 240px
  transition: width 0.5s
  height: 100%
  cursor: pointer
  border-right: 1px solid #FDFBF7
  display: flex
  flex-direction: column
  justify-content: space-between
  position: relative
  background: #0000004f
  .icon_narrow
    position: absolute
    top: 9%
    right: -15px
    transform: rotate(0deg)
    transition: transform 0.5s
    z-index: 99
  .icon_narrow_rotate
    position: absolute
    top: 9%
    right: -15px
    transform: rotate(180deg)
    transition: transform 0.5s
    z-index: 99
  .app_name
    height: 71px !important
    padding: 0 8px 44px 8px
    color: #635F41
    font-size: 18px
    font-weight: 400
    line-height: 27px
  .app_name_narrow
    padding: 35px 8px 0 8px
    display: flex
    justify-content: center
  .list_item
    ul
      background-color: #ffffff
      li
        font-size: 12px
        line-height: 18px
        letter-spacing: 0.5px
        font-weight: 500
        border-radius: 8px
        color: #1D1C16
        min-height: 40px
        background-color: #ffffff
        flex-direction: column
        &.border_list_item
          border-top: 1px solid #FDFBF7
        &:hover
          background-color: rgba(0, 0, 0, 0.08)
        div
          display: flex
          align-items: center
  .list_item_narrow
    display: none
  .section_bottom
    .add_group
      border-bottom: 1px solid #E8E2D0
      padding: 8px 0
      .add_group_box
        padding: 9px 16px 10px 12px
        display: flex
        align-items: center
        svg
          width: 18px
          height: 18px
          margin-right: 10px
        span
          font-size: 14px
          line-height: 21px
          letter-spacing: 0.1px
          font-weight: 500
          text-align: center
    .add_group_narrow
      display: none
    .list_item_footer
      display: flex
      margin-top: 2px
      .item_footer_active
        width: 56px
        height: 56px
        background: #EAE3BD
        border-radius: 100px
    .list_item_footer_narrow
      display: flex
      flex-direction: column
  .active
    background-color: #FFE600 !important
  .line_menu
    height: 1px
    margin: 4px 0
    background-color: #E8E2D0
.sidebar_narrow
  width: 70px
  transition: width 0.5s
.sidebar_sp
  display: none
.child_box
  padding: 0 0 0 12px
.child1_box
  padding: 0 0 0 24px
@media only screen and (max-width: 768px)
  .sidebar, .sidebar_sp, .sidebar_sp_toggle
    display: none
    .icon_narrow
      display: none
  .sidebar_sp, .sidebar_sp_toggle
    display: none
    width: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 100
  .sidebar_sp_toggle
    display: flex
  .section_top
    overflow-y: hidden

