.text-icon-cell .flex {
  display: flex !important;
}
.text-icon-cell .flex-row {
  flex-direction: row !important;
}
.text-icon-cell .items-center {
  align-items: center !important;
}
.text-icon-cell .justify-center {
  justify-content: center !important;
}
.text-icon-cell .grow {
  flex-grow: 1 !important;
}
.text-icon-cell .text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-icon-cell .h-4_5 {
  height: 18px !important;
}
.text-icon-cell .w-4_5 {
  width: 18px !important;
}
