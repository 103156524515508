@use '../../styles/utils' as *
$cBorder: $gray300

.root
  position: absolute
  display: flex
  align-items: center

.label
  font-size: 11px
  color: #959181
  text-align: center
  width: fit-content
